.profile-pic{
    height: 200px;
    width: 200px;
    transform: scale(1,1);
    transition: 1s;
}

.icon-container{
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .3s ease;
}

.edit-icon{
    color: #555aa1;
}
.edit-icon:hover{
    cursor: pointer;
}

.image-preview{
    height: 200px;
    width: 200px;
    background-color: rgb(194, 194, 194);
    border-radius: 10px;
    margin-bottom: 1em;
    border: none;
}

#custom-modal .modal-content{
    border: none;
    border-radius: 15px;
    box-shadow: 0px 0px 48px 0px rgba(13, 17, 77, 0.144);
}

.image-container{
    position: relative;
    height: 100%;
    width: 100%;
    transform: scale(1,1);
    transition: 1s;
}
.image-container:hover{
    cursor: pointer;
}
.image-container:hover .icon-container {
    opacity: 1;
}
.image-container:hover > .profile-pic{
    transform: scale(0.95, 0.95);
    filter: opacity(0.55);
    -webkit-filter: opacity(0.55);
    cursor: pointer;
}

.on-image-icon{
    color: white;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.user-info{
    font-size: 18px;
}

.custom-typeahead > div{
    border: 1px solid #ff6175;
}
.custom-typeahead-studyingto > div > input{
    border: 1px solid #ff6175;
}

@media (max-width: 600px){
    .f-col{
        flex-direction: column;
    }
}
