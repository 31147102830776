.vikariat-body{
    background-color: #F6F6F7;
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.topOfPage{
    background-color: white;
    position: relative;
}

/* .push-right{
    padding-left: 200px;
} */

.list-headers{
    
    font-weight: 600;
    font-size: large;
}
.teacher-name{
    color: black;
    font-weight: 600;
    font-size: medium;
}
/* .teacher-name:hover{
    color: rgba(0, 0, 0, 0.637);
} */


.gray-line{
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    /* margin-bottom: 15px; */
    padding-bottom: 15px;
    padding-top: 15px;
}
.v-row-hover{
    color: black;
}
.v-row-hover:hover{
    color: black;
    background-color: rgba(0, 0, 0, 0.068);
    text-decoration: none;
}

.text-content{
    
    font-size: medium;
}
.vikariat-card-list{
    border-radius: 15px;
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10);
}
.vikariat-card-block{
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10);
    border-radius: 10px;
    color: #000;
    transition: all .2s ease-in-out;
}
.vikariat-card-block:hover{
    color: black;
    text-decoration: none;
    transform: scale(1.05);
}
.block-title{
    display: flex;
    
}

.edit-job-icon{
   width: 26px;
   height: auto;
}
.edit-job-icon g{
    fill: black
}
.edit-job-icon path{
}

.edit-ellipsis{
    width: 5px;
}
.edit-ellipsis:hover{
    cursor: pointer;
}



/* viewUserProfile */
.user-profile-body{
    background-color: #F6F6F7;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 6rem;
    padding-top: 120px;
} 


@media (max-width: 600px){
    .vikariat-body{
        padding-left: 0;
    }
    .user-profile-body{
        padding-left: 0;
        padding-top: 0;
    }
  }