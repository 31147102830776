.go-back-icon {
    font-size: 30px;
    margin-top: 0.5em;
    color: #555aa1;
}

.got-job-container {
    background-color: #35b85a;
    height: 13em;
    border-radius: 14px;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.other-got-job-container {
    background-color: #ff6175;
    height: 13em;
    border-radius: 14px;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.got-job-text {
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: white;
    margin: 0;
}

.got-job-text-2 {
    text-align: center;
    font-size: 1.8em;
    font-weight: 400;
    color: white;
    margin: 0;
}

.got-job-icon {
    width: 30%;
}

.full-width {
    width: 100%;
}

.apply-consent-modal .modal-content {
    border: none !important;
    border-radius: 16px;
    outline: none;
    background-color: #f6f6f7;
    padding-left: 15px;
    padding-right: 15px;
}

.apply-consent-modal-error .modal-content {
    border: none !important;
    border-radius: 16px;
    outline: none;
    background-color: #ff6175;
    padding-left: 15px;
    padding-right: 15px;
}

/* .move-down {
  top: 35%;
} */

/* NOTE: Used wherever sort is not part of page basically TAGS: -main -css */
.viewjob-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /*  overflow-y: auto; */
}

@media (max-width: 600px) {
    .got-job-icon {
        width: 100%;
    }
}
