.temp-body {
    min-height: 100vh;
    padding-left: 6rem;
    padding-right: 15px;
    padding-top: 120px;
    /* display: flex;
      width: 100%;
      align-items: stretch; */
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
    margin-left: 0;
    margin-right: 0;
    min-height: 100vh;
    /* background-color: #f5f6fa!important;  */
}

.push-right {
    padding-left: 210px !important;
}

.topOfPage {
    background-color: white;
    position: relative;
}

.block-col {
    grid-column-start: 1;
}

.image-container {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0px 0px 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.anslagstavlan-hr {
    background-color: white;
    width: calc(100% - 30px);
    border: none;
    height: 2px;
}

.img-size {
    max-width: 82px;
    max-height: 82px;
    border-radius: 10px;
}

.job-header {
    color: #555aa1;
    margin-left: 0;
}

.job-m-header {
    color: #555aa1;
    margin-left: 20px;
    margin-right: 0;
    align-items: center;
}

.job-h-bg {
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
    height: 4.5em;
    background-color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-bottom: 0.7em;
}

.arrow-down {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    left: 47%;
    top: 100%;
}

.user-info {
    font-size: 10px;
    display: inline-block;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 1.8em;
}

.card-block {
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    border-radius: 10px;
    text-decoration: none;
    color: rgb(46, 47, 49);
    transition: all 0.2s ease-out;
    text-align: center;
    min-width: 300px;
    max-width: 600px;
}

.card-block:hover {
    text-decoration: none;
    color: rgb(46, 47, 49);
    transform: scale(1.01);
    cursor: pointer;
}

.truncate-reason-text {
    width: 50vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
}

.custom-hr {
    height: 2px;
    padding: 0;
    margin: 0;
    color: white;
    width: 100%;
    background-color: white;
}

.vr {
    border-left: 1px solid #d2d3d6;
    height: 80%;
    background-color: #d2d3d6;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.vr-extra {
    border-left: 1px solid #d2d3d6;
    height: 80%;
    background-color: #d2d3d6;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-left: 4rem;
}

.custom-card {
    flex: 1;
    flex-direction: row;
    border-radius: 10px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    outline: none;
    border: none;
    overflow: hidden;
}

.arrow-container {
    width: 6em;
    display: flex;
    align-items: center;
}

.arrow-container:hover {
    cursor: pointer;
}

/*custom body for bootstrap Card*/
.custom-body {
}

.vertical-hr-chosen {
    height: 80px;
    width: 1px;
    background-color: white;
    color: white;
}

.vertical-hr {
    border: none;
    border-left: 0px solid #dcdcdc;
    height: 80px;
    width: 1px;
    background-color: #dcdcdc;
}

.vertical-hr-small {
    border: none;
    border-left: 0px solid #dcdcdc;
    height: 60px;
    width: 1px;
    margin-left: 10px;
    margin-right: 2px;
    background-color: #dcdcdc;
}

.no-padding {
    /* padding: 12px 0px 10px 0px; */
    padding: 0;
}

.card-margin-bottom {
    margin-bottom: 2em;
}

.sort-dropdown {
    width: 30%;
    z-index: 12;
    min-width: 200px;
}

.custom-dropdown {
    background-color: rgb(239, 243, 250);
    border: 1px solid #555aa1;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    font-size: 14px;
    border-radius: 20px;
    color: black;
    z-index: 12;
}

.custom-dropdown:active {
    background-color: #555aa1 !important;
    color: white !important;
    border-color: #555aa1 !important;
}

.custom-dropdown:focus {
    background-color: #555aa1 !important;
    color: white !important;
    border-color: #555aa1 !important;
    box-shadow: 0 0 0 0.2rem rgb(85, 90, 161 / 25%) !important;
}

.custom-dropdown:hover {
    background-color: #555aa1;
    border: 1px solid #555aa1;
}

.vikaaria-dropdown {
    background-color: #555aa1;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    z-index: 12;
    font-size: 16px;
    height: 2.175rem;
    border: 2px solid #555aa1;
    font-weight: 500;
    border-radius: 10px;
    color: white;
    width: 10em;
    display: flex;
}

.vikaaria-dropdown.dropdown-menu {
    width: 100% !important;
}

.vikaaria-dropdown.dropdown-toggle:after {
    content: none;
}

.vikaaria-dropdown:active {
    background-color: #555aa1 !important;
    color: white !important;
    border-color: #555aa1 !important;
}

.vikaaria-dropdown:focus {
    background-color: #555aa1 !important;
    color: white !important;
    border-color: #555aa1 !important;
    box-shadow: 0 0 0 0.2rem rgb(85, 90, 161 / 25%) !important;
}

.vikaaria-dropdown:hover {
    background-color: #555aa1;
    border: 1px solid #555aa1;
}

.small-profile-pic {
    height: 60px;
    width: 60px;
}

.small-profile-pic:hover {
    cursor: pointer;
}

.admin-dropdown {
    background-color: white;
    color: black;
    border: none;
}

.block:hover {
    color: #555aa1;
    cursor: pointer;
}

.block:active {
    color: blue;
}

.list:hover {
    color: #555aa1;
    cursor: pointer;
}

.list:active {
    color: blue;
}

.chosenIndicator {
    width: 5px;
    background-color: rgb(87, 185, 87);
}

.notChosenIndicator {
    width: 10px;
    background-color: white;
}

.notChosenIndicator:hover {
    cursor: pointer;
    background-color: rgba(243, 243, 243, 0.815);
}

.user-name {
    margin: 0;
    font-size: 16px;
}

.admin-text {
    font-size: 14px;
}

.text-area {
    cursor: pointer;
}

.sort-down-icon {
    color: gray;
}

.sort-down-area {
    cursor: pointer;
}

.view-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    transform: translateX(20px);
}

.mobile-view-container {
    display: flex;
    width: 100%;
    color: #555aa1;
    justify-content: end;
    align-items: center;
    flex-direction: row;
    margin-left: auto;
    z-index: 100;
}

.mobile-job-header {
    display: block;
}

/* .view-svg{
    margin-right: 7px;
    width: 30px;
} */
.view-svg {
    margin-right: 7px;
}

.desktop-view-svg {
    width: 20px;
    margin-right: 7px;
    margin-top: 3px;
}

.view-counter {
    margin: 0;
    margin-right: 16px;
    font-weight: 600;
    font-size: 16px;
    margin-top: auto;
}

.block-bell-container {
    flex-direction: row;
    display: flex;
    align-items: center;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
}

.bgGreen {
    background-color: #35b85a;
}

.bgPurple {
    background-color: #7b80dc;
}

.bgYellow {
    background-color: #ffa53a;
}

.f-row {
    flex-direction: row;
}

@media (max-width: 600px) {
    .temp-body {
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;
    }

    .main {
        margin: 0;
    }

    .img-size {
        margin: 15px 0px 0px 15px;
        height: 70px;
        width: 70px;
    }
}
