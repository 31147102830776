.sidebar{
  
}

.color-white{
  color: rgb(104, 104, 104);
  
}

.bg-nav{
  background-color: white;
}

.appCount-vik{
  width: 25px;
  height: 25px;
  background-color: rgb(238, 67, 67);
  color: white;
  margin-left: 5.9em;
  display: inline-block;
  justify-content: center;
  font-weight: normal;
  align-content: center;
  flex-direction: column;
  border-radius: 50%;
  
}

.appCount{
  width: 25px;
  height: 25px;
  background-color: rgb(238, 67, 67);
  color: white;
  margin-left: 3em;
  display: inline-block;
  justify-content: center;
  font-weight: normal;
  align-content: center;
  flex-direction: column;
  border-radius: 50%;
  
}
.countText {
  margin-top: 2px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.active-custom{
  color: #555aa1;
  font-weight: bold;
  border-right: 5px solid #555aa1;
  border-radius: 5px;
}
