@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);

body {
  margin: 0;
  font-size: 10px !important;
  font-family: 'Poppins', sans-serif !important;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f6fa!important;
  /* background-color: #555aa1!important; */
  min-height: 100%;
}
html{
  height: 100%;
  margin: 0;
  padding: 0;
  /* min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); */
}
/* @font-face{
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face{
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./fonts/Roboto-Medium.ttf) format('truetype');
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  position: relative;
}
/* 
.ios-head{
  display: none;
} */

/* small text */
.st {
  font-size: 1.2em;
}

.custom-template {
  background-color: #555aa1;
  width: 100%;
}
/* wrapper for spinner */
.spin-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #f6f6f7;
}
/* Animations */
.fadein {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.spinning {
  position: absolute;
  width: 80px;
  height: 80px;
  /* margin: -75px 0 0 -75px; */
  top: 50%;
  left: 50%;
  animation: spin 1.5s infinite linear;
}

.confirm {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #040841;
}
/*  */
.confirm-email-splash {
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.spinning svg {
  border-radius: 50%;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(
      0deg
    ); /* 
      -ms-transform: rotate(0deg);
      transform: rotate(0deg); */
  }
  100% {
    -webkit-transform: rotate(
      360deg
    ); /* 
      -ms-transform: rotate(360deg);
      transform: rotate(360deg); */
  }
}

/*   @media (max-width: 600px){
    .ios-head{
      background-color: red;
      z-index: 99;
      position: fixed;
      height: 50px;
      width: 100%;
      top: -40px;
      display: initial;
    }
  } */

.mobile-chosen-container {
    width: 1.8rem;
    background-color: #35b85a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.vikariat-chosen-container {
    border-radius: 50%;
    width: 10%;
    background-color: #35b85a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-not-chosen-container {
    width: 1.8rem;
    background-color: #ff6175;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-check {
    filter: brightness(0) invert(1);
    display: block;
    margin: 0 auto;
    height: 2em;
    width: 1.5em;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.vikariat-check {
    filter: brightness(0) invert(1);
    padding: .2em;
}

.no-applicants-card {
    border: none;
    background-color: white;
    color: rgb(255, 165, 58);
    font-size: 1.6em;
    font-weight: 600;
    text-align: center;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgb(13 17 77 / 10%);
}

.date-sub-color {
    color: #9192a2;
}

.truncate {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-small {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mobile-card {
    flex: 1 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgb(13 17 77 / 10%);
    outline: none;
    border: none;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    color: black !important;
    transition: all 0.2s ease-out;
    height: 10em;
    text-decoration: none !important;
}

.mobile-card a:hover {
    color: black;
}

.inactive-m-card {
    flex: 1 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgb(13 17 77 / 10%);
    outline: none;
    border: none;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    color: black !important;
    transition: all 0.2s ease-out;
    height: 10em;
    text-decoration: none !important;
    opacity: 0.35;
    /*  z-index: -1; */
}

.icon-wrapper {
    height: 100%;
}

.icon-wrapper .default-icon {
    justify-content: center;
    align-items: center;
    height: calc(50% - 10px) !important;
}

.icon-wrapper .no-bookmark {
    justify-content: center;
    align-items: center;
    height: 100%;
}

.icon-wrapper .bookmark-icon {
    margin-top: 10px;
}

.form-control {
    font-size: 16px;
    border-color: #565a9c;
}

.form-control:focus {
    border-color: #565a9c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #bcaff6ea;
}

.btn-sos {
    background-color: #ff6175;
    font-size: 20px;
    border-radius: 10px;
    font-weight: 600;
    border: 2px solid #ff6175;
    color: white;
}

.btn-sos:hover {
    background-color: #fff;
    border: 2px solid #ff6175;
    color: #ff6175;
}

.btn-customModalError {
    background-color: white;
    font-size: 20px;
    border-radius: 10px;
    font-weight: 400;
    color: #ff6175;
    margin: 0;
}

.btn-customModalError:hover {
    background-color: rgba(255, 255, 255, 0.425);
    color: white;
}

.errorApplyCloseBtn:hover {
    transform: scale(1.1);
}

.btn-custom {
    background-color: #555aa1;
    font-size: 15px;
    text-transform: uppercase;
    height: 2.8rem;
    border: 2px solid #555aa1;
    font-weight: 600;
    letter-spacing: 0.03em;
    border-radius: 10px;
    color: white;
}

.btn-custom:hover {
    background-color: #fff;
    border: 2px solid #555aa1;
    color: #555aa1;
}

.btn-remember {
    background-color: #555aa1;
    height: 3rem;
    width: 3rem;
    border-radius: 10px;
    color: white;
}

.btn-remember:hover {
    background-color: rgba(85, 90, 161, 0.7);
}

.btn-no-remember {
    background-color: #f6f6f7;
    height: 3rem;
    width: 3rem;
    border-radius: 10px;
    color: white;
}

.btn-no-remember:hover {
    background-color: #fff;
    border: 2px solid #555aa1;
    color: #555aa1;
}

.btn-custom-reverse {
    background-color: white;
    border: 2px solid #555aa1;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    height: 2.8rem;
    border-radius: 10px;
    color: #555aa1;
}

.btn-custom-reverse:hover {
    background-color: #555aa1;
    border: 2px solid #555aa1;
    border: none;
    color: white;
}

.btn-customDropdown {
    /* background-color: white; */
    font-size: 1.4em;

    font-weight: bold;
    color: black;
}

.btn-customDropdown::after {
    color: #afafaf;
    border-width: 7px;
}

.btn-customDropdown:hover {
    /* background-color: rgba(255, 255, 255, 0.658); */
    color: rgba(0, 0, 0, 0.767);
}

.btn-customDropdown:focus {
    box-shadow: none;
}

.btn-newjob {
    background-color: #555aa1 /* #F6F6F7 */;
    font-size: 14px;

    color: #f6f6f7 /* #555aa1 */;
    padding: 14px 24px;
    border-radius: 10px;
}

.btn-newjob:hover {
    background-color: #f6f6f7 /* #555aa1 */;
    color: #555aa1 /* white */;
}

.btn-newjob:hover img {
    filter: none;
}

.btn-newjob img {
    filter: brightness(0) invert(1);
}

.btn-newjob-highlighted {
    background-color: rgb(38, 156, 211);
    font-size: 1.4em;
    color: white;
    padding: 14px 24px;
    border-radius: 16px;
    text-align: left;
}

.btn-newjob-highlighted:hover {
    background-color: rgb(95, 172, 207);
    color: white;
}

.btn-newjob-highlighted-2 {
    background-color: white;
    font-size: 1.4em;
    border: 2px solid #555aa1;
    color: #555aa1;
    padding: 14px 24px;
    border-radius: 16px;
    text-align: left;
}

.btn-newjob-highlighted-2:hover {
    background-color: #555aa1;
    border: 2px solid #555aa1;
    color: white;
}

.btn-newjob-highlighted-2:hover img {
    filter: brightness(0) invert(1);
}

.btn-sortActivate {
    background-color: #35b85a;
    font-size: 1.6em;
    color: white;
    border-radius: 12px;
}

.btn-sortActivate:hover {
    background-color: white;
    color: #35b85a;
    border: 1px solid #35b85a;
}

.btn-active {
    background-color: #35b85a;
    font-size: 1.6em;
    color: white;
    border-radius: 12px;
}

.btn-active:hover {
    background-color: white;
    color: #35b85a;
    border: 1px solid #35b85a;
}

.btn-customDelete {
    background-color: #ff6175;
    font-size: 15px;
    height: 2.8rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.03em;
    border-radius: 10px;
    color: white;
}

.btn-customDelete:hover {
    background-color: white;
    color: #ff6175;
    border: 2px solid #ff6175;
}

.btn-customDelete-reverse {
    background-color: white;
    font-size: 15px;
    height: 2.8rem;
    border: 2px solid #ff6175;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.03em;
    border-radius: 10px;
    color: #ff6175;
}

.btn-customDelete-reverse:hover {
    background-color: #ff6175;
    color: white;
    border: 2px solid #ff6175;
}

.btn-customSuccess {
    font-size: 15px;
    color: white;
    height: 2.8rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.03em;
    border-radius: 10px;
    background-color: #555aa1;
}

.btn-customSuccess:hover {
    background-color: white;
    color: #555aa1;
    border: 2px solid #555aa1;
}

.btn-deactive {
    background-color: #ff6175;
    font-size: 1.6em;

    color: white;
    border-radius: 12px;
}

.btn-deactive:hover {
    background-color: white;
    color: #ff6175;
    border: 1px solid #ff6175;
}

.btn-imageBtn {
    background-color: #555aa1;
    color: white;
    font-size: 15px;
    font-weight: 500;
    /* width: 14rem; */
    height: 2.8rem;
    border-radius: 10px;
}

.btn-imageBtn:hover {
    background-color: white;
    border: 2px solid #555aa1;
    color: #555aa1;
}

.btn-imageBtn-reverse {
    background-color: white;
    color: #555aa1;
    font-size: 15px;
    border: 2px solid #555aa1;
    font-weight: 600;
    height: 2.8rem;
    border-radius: 10px;
    margin-right: auto;
}

.btn-imageBtn-reverse:hover {
    background-color: #555aa1;
    color: white;
}

.btn-saveTeacher {
    /* width: 14rem; */
    height: 2.8rem;
    background-color: #555aa1;
    color: white;
    font-size: 18;
    font-weight: bold;
    border-radius: 10px;
}

.btn-saveTeacher:hover {
    background-color: white;
    border: 2px solid #555aa1;
    color: #555aa1;
}

.red-variant {
    background-color: #ff6175;
}

.red-variant:hover {
    color: #ff6175;
    border: 1px solid #ff6175;
}

.btn-sort {

}

.btn-sort:hover {

}

.btn-sort:focus {
    box-shadow: none;
}

.btn-sort:active {
    color: blue;
}

.list-group-item {
    background-color: #f8f8fa;

}

/* Pointer event curosr. (cursor: pointer;) */
.pe-cursor {
    cursor: pointer;
}

@media (max-width: 600px) {
    .btn-customDropdown::after {
        border-width: 6px;
    }
}
.body{
    min-height: 100vh;
    background-color: #f8f8fa;
    position: relative;
}

.vertical-center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.forgot-link{
    font-size: 14px;
    
    color: #5333bd;
    text-decoration: underline;
}
.forgot-link:hover{
    color: #5333bd6e;
}
.pre-text {
    font-size: 14px;
    
}

.saved-email{
    font-size: 16px;
    
    color: #00002f;
    text-align: center;
}
.change-email{
    font-size: 16px;
    text-decoration: underline;
    
    color: #5333bd;
}
.change-email:hover{
    color: #5333bd6e;
}

.re-captcha{
    font-size: 12px;
    
    color: #a8a8a8;
    text-align: center;
}

.re-captcha a{
    color: #484848;
}

hr{
    height: 1px;
    color: #d2d3d6;
    background-color: #d2d3d6;
    width: 60%;
}
.body{
    margin: 0;
    padding: 0;
    height: 100vh;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: #f8f8fa;
}

.reg2-body{
    height: 100vh;
    width: 100vw;
}

.vertical-center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.body{
    height: 100vh;
    background-color: #f8f8fa;
    position: relative;
}

.vertical-center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


hr{
    height: 1px;
    color: #d2d3d6;
    background-color: #d2d3d6;
}
.mobile-h {
    padding: 0;
    margin: 0;
    height: 100%;
    /* display: flex;
    flex-direction: column; */
}

/* .mobile-h > *{
    flex-shrink: 0;
} */
.mobile-header-image {
    /* z-index: 1; */
    /* width: 100%; */
    /* height: auto;
    margin: auto; */
    /* position: absolute; */
}

.mobile-l {
    width: 50%;
    height: auto;
    position: relative;
    top: 26%;
    /* z-index: 2; */
}

.logo-anim {
    animation: logo-reveal 1s;
    -webkit-animation: logo-reveal 1s;
}

.avatar {
    width: 18%;
    height: auto;
    position: absolute;
    top: 60%;
    left: 41%;
    border-radius: 20px;
}

.m-header {
    position: absolute;
    background-image: url(https://app.vikaaria.se/static/media/mobile_header.c950c92b.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 80vw;
    text-align: center;
    transition: 1s ease;
    width: 100%;
}

.m-header-scroll {
    position: absolute;
    background-image: url(https://app.vikaaria.se/static/media/mobile_header.c950c92b.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 0vw;
    text-align: center;
    transition: 1s ease;
}

.m-cards {
    /* transform: translateY(-14vw); */
    padding-bottom: 80px;
    padding-top: 80vw;
    height: 100%;
}

.m-cards-lessPad {
    padding-bottom: 80px;
    padding-top: 61vw;
    height: 100%;
}

.m-cards-scroll {
    transform: translateY(-41vw);
    padding-bottom: 1rem;
}

.header-text {
    color: white;
    font-size: 1.8em;
    position: absolute;
    width: 100%;
    height: auto;
    top: 85%;
    font-weight: 500;
}

.mobile-header-svg {
    transform: translateY(-1px);
    width: 23px;
    filter: brightness(0) invert(1);
}

.mobile-header-svg-applied {
    transform: translateY(-3px);
    width: 21px;
    filter: brightness(0) invert(1);
}

.mobile-settings-svg {
    width: 23px;
    filter: brightness(0) invert(1);
}

.mobile-header-svg-other {
    transform: translateY(-1px);
    width: 30px;
    filter: brightness(0) invert(1);
}

.mobile-header-svg-more {
    transform: rotate(90deg);
    width: 6px;
    filter: brightness(0) invert(1);
}

.mobile-image-container {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}

/* .m-image{
    margin-top: 10px!important;
    transform: translate(-5px);
} */
.teacher-svg-size {
    width: 21px;
}

.h-up {
    top: -100%;
}

.chosen {
    background-color: #35b85a;
    color: white !important;
}

.substitute-unavailable {
    opacity: 0.4 !important;
    cursor: not-allowed;

    flex: 1 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    outline: none;
    border: none;
    overflow: hidden;
    text-decoration: none;
    color: black;
    max-height: 108px;
    min-height: 108px;;
}

.not-chosen {
    background-color: #ff6175;
    color: white !important;
}

.reg {
    color: #2e2f31;
}

.swipe-delete-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    background-color: #ff6175;
    border-radius: 14px;
    height: 10em;
    padding-left: 0;
    min-width: 9em;
}

.swipe-restore-container {
    background-color: #35b85a;
    border-radius: 14px;
    height: 10em;
    padding-left: 0;
    min-width: 9em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
}

.swipe-inactive-container {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    background-color: #35b85a;
    border-radius: 14px;
    height: 10em;
    padding-left: 0;
    min-width: 9em;
}

.swipe-text {
    color: white;
    padding: 0;
    margin: 0;
}

.swipe-icon {
    width: 4em;
    transform: scale(-1, 1);
}

.swipe-icon-dots {
    filter: brightness(0) invert(1);
    width: .7em;
    transform: rotate(90deg);
}

.swipe-trash-icon {
    width: 2.5em;
}

.inactive-title {
    padding-top: 1em;
    font-size: 1.8em;
    margin-left: 1em;
    color: white;
    font-weight: 500;
    /*  z-index: -1; */
}

.inactive-section {
    /*  background-color: rgba(0, 2, 22, 0.568); */
    background-color: rgba(39, 47, 112, 0.863);
    /* background-color: rgba(39, 47, 112);
    opacity: 50%;
    z-index: 99; */
    /* min-height: 25vh; */
    /* flex-grow: 1; */
}

.hide-section {
    display: none;
}

.sticky-backbar {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    border-radius: 14px;
}

.backbar {
    position: sticky;
    top: 0px;
    align-self: flex-start;
}

@keyframes logo-reveal {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 600px) {
    .mobile-margin {
        margin-top: 32vh;
    }
}
.navbar {
  background-color: #ffffff;
  height: 120px;
  display: flex;
  justify-content: start;
  align-items: left;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  padding-left: 6rem;
}

.version {
  color: #8d9298;
  bottom: 0;
  position: absolute;
  margin-left: 5px;
  cursor: none;
}

.version-middle {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 26px; /* Need a specific value to work */
}

.menu-bars {
  /* margin-left: 2rem; */
  padding: 5px 5px 0px 5px;
  transition: 350ms;
  border-radius: 16px;
  color: #555aa1;
  display: none;
}
.menu-bars:hover {
  color: #555aa1;
}

.activeLink img {
  fill: #555aa1;
}

.bars-active {
  color: #555aa1;
  background-color: #f6f6f7;
  margin-left: 230px;
  display: block;
}

.nav-separator {
  width: 90%;
  height: 1px;
  border: 0px;
  background-color: #f5f5f5;
}

.top-nav-items {
  margin-left: auto;
}

.nav-menu {
  background-color: #ffffff;
  height: 100vh;
  width: 6rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: 850ms;
  box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
  /* display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 100000; */
}

.nav-menu.active {
  left: 0;
  transition: 850ms;
  z-index: 100000;
  display: flex;
  padding-top: 0;
  justify-content: center;
  width: 300px;
  background-color: #ffffff;
}

.page-title {
  color: #2e2f31;
  font-weight: 700;
  font-size: 30px;
  transition: 850ms;
  margin-bottom: 0;
}

.change-user-type {
  display: flex;
}
.change-user-type-text {
  text-decoration: none;
  color: #8d9298;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
input.change-user-switch {
  position: relative;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #d0dadc;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #fff;
  transition-duration: 200ms;
  margin-left: auto;
  margin-right: auto;
}
input.change-user-switch::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 26px;
  height: 26px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}
input.change-user-switch:checked {
  border-color: #827aff;
  box-shadow: inset 20px 0 0 0 #827aff;
}
input.change-user-switch:checked::after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}
.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  list-style: none;
  height: 60px;
  position: relative;
}
.nav-text:hover {
  background-color: #f6f6f7;
  width: 98%;
}
.nav-text:hover .app-count-temp {
  margin-right: 9px;
}
.nav-text:hover .app-count {
  margin-right: 9px;
}
.nav-text:hover img {
  filter: invert(51%) sepia(7%) saturate(245%) hue-rotate(169deg)
    brightness(88%) contrast(90%);
}
/* .move-left{
    transform: translateX(-10px);
  } */
/*  .nav-text:hover .vikariat-copy{
    filter: invert(38%) sepia(2%) saturate(60%) hue-rotate(202deg) brightness(111%) contrast(97%);
  } */
.vikariat-copy {
  filter: invert(62%) sepia(7%) saturate(286%) hue-rotate(172deg)
    brightness(92%) contrast(84%);
}
.nav-text a {
  text-decoration: none;
  color: #8d9298;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  border-radius: 4px;
}
.nav-text a:hover {
  color: #717579;
  font-weight: 600;
}

.nav-text-active {
  display: flex;
  justify-content: start;
  align-items: center;
  list-style: none;
  height: 60px;
  position: relative;
}
.nav-text-active a {
  text-decoration: none;
  color: #555aa1;
  font-weight: 600;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  border-radius: 4px;
}
.nav-text-active g {
  fill: #555aa1;
}

.nav-logo {
  display: flex;
  justify-content: start;
  align-items: center;
  list-style: none;
  height: 120px;
  font-size: 25px;
  margin-left: 1.5rem;
}

.nav-logo a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.app-count {
  background-color: rgb(238, 67, 67);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: auto;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-right: 15px;
}
.app-count p {
  color: white;
  font-weight: bold;
  margin: auto;
}

.app-count-temp {
  background-color: rgb(238, 67, 67);
  width: 24px;
  height: 24px;
  font-size: 14px;
  border-radius: 50%;
  margin-left: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-right: 15px;
}
.app-count-temp p {
  color: white;
  font-weight: bold;
  margin: auto;
}

.active-count {
  margin-right: 10px;
}

.vikariat-svg {
  /*  filter: brightness(0) invert(1); */
  width: auto;
  height: 20px;
}

.button-vikariat-svg {
  /* filter: invert(38%) sepia(9%) saturate(3740%) hue-rotate(198deg) brightness(87%) contrast(80%); */
  /* filter: brightness(0) invert(1); */
  width: auto;
  height: 24px;
}

.active-svg {
  filter: invert(38%) sepia(9%) saturate(3740%) hue-rotate(198deg)
    brightness(87%) contrast(80%);
}

.active-page {
  font-weight: bold;
  height: 45px;
  width: 6px;
  background-color: #555aa1;
  border-radius: 6px 6px;
  margin-left: auto;
  padding: 0;
}
.active-page .nav-text a {
  color: #555aa1;
}

.nav-menu-items {
  /* width: 100%; */
  padding: 0;
}
.active-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  /* margin-left: 16px; */
}

.dd-icon {
  width: 16px;
  height: auto;
}
.dropdown-menu {
  width: 100%;
  border: 4px solid rgb(236, 237, 242);
  border-radius: 20px;
  box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
}
.dd-item {
  color: #8d9298;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dd-item:hover {
  color: #555aa1;
  font-weight: bold;
}
.dd-item:active {
  background-color: #555aa1;
}
.dd-item:active img {
  filter: brightness(0) invert(1);
}
.dd-item:hover a {
  color: #555aa1;
}
.dd-item a {
  color: #8d9298;
  text-decoration: none;
}
.dd-item a:hover {
  color: #555aa1;
  text-decoration: none;
}

.slide-container {
  max-height: 52px;
  max-width: 50px;
  overflow: hidden;
}

.slide-container img {
  width: auto;
  height: 100%;
}

.full-container {
  max-width: 219px;
  position: relative;
  float: left;
  height: 100%;
  overflow: hidden;
  -webkit-animation: reveal 850ms ease;
  -webkit-animation-fill-mode: backwards;
}

.full-container .overlay {
  width: 100%;
  height: 100%;
  background: #6e8eea;
  position: absolute;
  transform: scaleX(0);
  transform-origin: 0% 50%;
  animation: anim 850ms;
}

.text-anim {
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 45px;
}

.icon {
  width: 25px;
  filter: invert(80%) sepia(20%) saturate(192%) hue-rotate(189deg)
    brightness(110%) contrast(84%);
}

/* mobile styling below */
.mobile-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5rem;
  border: none;
  /* box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10); */
  filter: drop-shadow(0px 0px 24px rgba(13, 17, 76, 0.1));
  background-color: #ffffff;
  display: flex;
  /*  overflow-x: auto; */
  z-index: 1000;
  transition: all ease 1s;
}

.mobile-nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 33.33%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
  z-index: 10;
  color: #8d9298 !important;
  text-decoration: none !important;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}
.mobile-nav-link:focus {
  text-decoration: none;
  color: #555aa1;
}
.mobile-nav-link:hover :focus {
  background-color: #eeeeee;
  text-decoration: none;
  color: #555aa1;
}
.mobile-nav-text {
  height: 1rem;
  text-align: center;
  font-weight: bold;
}
.mobile-icons {
  font-size: 1.4em;
  height: 2rem;
}
.og-color {
  filter: invert(92%) sepia(6%) saturate(621%) hue-rotate(199deg)
    brightness(94%) contrast(89%) !important;
}

.mobile-active {
  color: #555aa1 !important;
}

.larger-icon {
  width: 30px;
  transform: translateX(-6px);
}
.svg-color {
  filter: invert(62%) sepia(6%) saturate(621%) hue-rotate(199deg)
    brightness(94%) contrast(89%);
}
.applied-svg {
  width: 23px;
}
.applied-icon {
  width: 25px;
  position: absolute;
  left: 36px;
}
.applied-mobile {
  width: 23px;
}
.vikariat-icon {
  width: 30px;
  position: absolute;
}
.vikariat-mobile {
  width: 28px;
}
.teacher-icon {
  width: 28px;
  position: absolute;
}
.user-vikariat-svg {
  width: 29px;
}
.settings-svg {
  width: 27px;
}
/*  .nav-text:hover .user-vikariat-svg{
    filter: invert(62%) sepia(6%) saturate(621%) hue-rotate(199deg) brightness(94%) contrast(89%); 
  }
  .nav-text:hover .applied-svg{
    filter: invert(62%) sepia(6%) saturate(621%) hue-rotate(199deg) brightness(94%) contrast(89%);
  } */

.teacher-m-icons {
  /* filter: invert(62%) sepia(6%) saturate(621%) hue-rotate(199deg) brightness(94%) contrast(89%);  */
  width: 27px;
}

.mobile-app-count {
  position: absolute;
  top: 12%;
  left: 17%;
  background-color: rgb(238, 67, 67);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 16px;
}
.mobile-app-count p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 0.8em;
}

.mobile-chosen-count {
  position: absolute;
  top: 12%;
  left: 50%;
  background-color: #35b85a;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 16px;
}
.mobile-chosen-count p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 0.8em;
}

/* CSS for ball that shows user to check profile */
.mobile-check-profile {
  position: absolute;
  top: 12%;
  left: 85%;
  background-color: #ff6175 /* rgb(238, 67, 67) */;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 16px;
}
.mobile-check-profile p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 1em;
}

.other-check-profile {
  position: absolute;
  top: 10%;
  left: 90%;
  background-color: #ff6175 /* rgb(238, 67, 67) */;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 16px;
}

.other-check-profile p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 1em;
}

.check-school {
  background-color: #ff6175 /* rgb(238, 67, 67) */;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.check-school p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 1em;
}

.dditem-check-profile {
  position: absolute;
  top: 17%;
  left: 75%;
  background-color: #ff6175 /* rgb(238, 67, 67) */;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 16px;
}
.dditem-check-profile p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 1em;
}

.circle-container {
  /* width: 80px;
    height: 100%;
    border-radius: 50%;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    margin-left: -10%;
    top: -30%;
    overflow: auto;
    z-index: -2; */
  border-radius: 50%;
  background-color: #ffffff;
  z-index: -2;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}
/* .circle-container::before{
    content: '';
    position: absolute;
    width: 150%;
    height: 50%;
    left: -25%;
    top: -10px;
    background: #ffffff;
  }
  .circle-container::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #ffffff;
    border-radius: 50%;
  }
 */
.add-icon {
  width: 60px;
  /* width: 35px; */
}
.active-add-svg {
  filter: invert(58%) sepia(30%) saturate(641%) hue-rotate(210deg)
    brightness(80%) contrast(89%);
}
.middle-btn {
  position: relative;
  top: -45%;
  height: 100px;
}

.nav-school-name {
  position: relative;
  text-align: center;
  list-style: none;
}
.nav-school-text {
  font-size: 1.8em;
  color: #555aa1;
  font-weight: 700;
  animation: text-reveal 1050ms;
  -webkit-animation: text-reveal 1050ms;
  text-decoration: underline;
}

/* animations and media query */
@keyframes reveal {
  0% {
    width: 50px;
  }
  100% {
    width: 100%;
  }
}
@keyframes text-reveal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .navbar {
    background-color: #ffffff;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: left;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    padding-left: 0rem;
  }
}

.profile-pic{
    height: 200px;
    width: 200px;
    transform: scale(1,1);
    transition: 1s;
}

.icon-container{
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .3s ease;
}

.edit-icon{
    color: #555aa1;
}
.edit-icon:hover{
    cursor: pointer;
}

.image-preview{
    height: 200px;
    width: 200px;
    background-color: rgb(194, 194, 194);
    border-radius: 10px;
    margin-bottom: 1em;
    border: none;
}

#custom-modal .modal-content{
    border: none;
    border-radius: 15px;
    box-shadow: 0px 0px 48px 0px rgba(13, 17, 77, 0.144);
}

.image-container{
    position: relative;
    height: 100%;
    width: 100%;
    transform: scale(1,1);
    transition: 1s;
}
.image-container:hover{
    cursor: pointer;
}
.image-container:hover .icon-container {
    opacity: 1;
}
.image-container:hover > .profile-pic{
    transform: scale(0.95, 0.95);
    filter: opacity(0.55);
    -webkit-filter: opacity(0.55);
    cursor: pointer;
}

.on-image-icon{
    color: white;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.user-info{
    font-size: 18px;
}

.custom-typeahead > div{
    border: 1px solid #ff6175;
}
.custom-typeahead-studyingto > div > input{
    border: 1px solid #ff6175;
}

@media (max-width: 600px){
    .f-col{
        flex-direction: column;
    }
}

.profile-body {
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}

.obligatory{
    color: #ff6175;
}


.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* .push-right{
    padding-left: 200px;
} */

/*lt = label text */
.lt{
    font-size: 1.4em;
}

.topOfPage {
    background-color: white;
    position: relative;
}


@media (max-width: 600px){
    .profile-body{
        padding-left: 0;
    }
  }
.reg-body{
    background-color: #F6F6F7;
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
}

@media (max-width: 600px){
    .mobile-style{
        max-width: 100%;
        max-height: 100%;
    }
}
.date-picker{
    z-index: 200;
}

.custom-date{
}
.pos-body {
    background-color: rgb(239, 243, 250);
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
option:disabled{
    color: rgba(230, 230, 230, 0.753);

}

.modal.sos-modal .modal-dialog .modal-content{
    border: none;
    border-radius: 20px;
    outline: none;
}

/* .push-right{
    padding-left: 230px;
} */

.subject-picker{
   
}
.react-datepicker-popper{
    z-index: 10000;
}

.topOfPage {
    background-color: white;
    position: relative;
}

@media (max-width: 600px){
    .pos-body{
        padding-left: 0;
    }
  }

  .add-job-pad{
      padding-left: 25px;
      padding-right: 30px;
  }

.temp-body {
    min-height: 100vh;
    padding-left: 6rem;
    padding-right: 15px;
    padding-top: 120px;
    /* display: flex;
      width: 100%;
      align-items: stretch; */
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
    margin-left: 0;
    margin-right: 0;
    min-height: 100vh;
    /* background-color: #f5f6fa!important;  */
}

.push-right {
    padding-left: 210px !important;
}

.topOfPage {
    background-color: white;
    position: relative;
}

.block-col {
    grid-column-start: 1;
}

.image-container {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0px 0px 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.anslagstavlan-hr {
    background-color: white;
    width: calc(100% - 30px);
    border: none;
    height: 2px;
}

.img-size {
    max-width: 82px;
    max-height: 82px;
    border-radius: 10px;
}

.job-header {
    color: #555aa1;
    margin-left: 0;
}

.job-m-header {
    color: #555aa1;
    margin-left: 20px;
    margin-right: 0;
    align-items: center;
}

.job-h-bg {
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
    height: 4.5em;
    background-color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-bottom: 0.7em;
}

.arrow-down {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    left: 47%;
    top: 100%;
}

.user-info {
    font-size: 10px;
    display: inline-block;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 1.8em;
}

.card-block {
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    border-radius: 10px;
    text-decoration: none;
    color: rgb(46, 47, 49);
    transition: all 0.2s ease-out;
    text-align: center;
    min-width: 300px;
    max-width: 600px;
}

.card-block:hover {
    text-decoration: none;
    color: rgb(46, 47, 49);
    transform: scale(1.01);
    cursor: pointer;
}

.truncate-reason-text {
    width: 50vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
}

.custom-hr {
    height: 2px;
    padding: 0;
    margin: 0;
    color: white;
    width: 100%;
    background-color: white;
}

.vr {
    border-left: 1px solid #d2d3d6;
    height: 80%;
    background-color: #d2d3d6;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.vr-extra {
    border-left: 1px solid #d2d3d6;
    height: 80%;
    background-color: #d2d3d6;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-left: 4rem;
}

.custom-card {
    flex: 1 1;
    flex-direction: row;
    border-radius: 10px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    outline: none;
    border: none;
    overflow: hidden;
}

.arrow-container {
    width: 6em;
    display: flex;
    align-items: center;
}

.arrow-container:hover {
    cursor: pointer;
}

/*custom body for bootstrap Card*/
.custom-body {
}

.vertical-hr-chosen {
    height: 80px;
    width: 1px;
    background-color: white;
    color: white;
}

.vertical-hr {
    border: none;
    border-left: 0px solid #dcdcdc;
    height: 80px;
    width: 1px;
    background-color: #dcdcdc;
}

.vertical-hr-small {
    border: none;
    border-left: 0px solid #dcdcdc;
    height: 60px;
    width: 1px;
    margin-left: 10px;
    margin-right: 2px;
    background-color: #dcdcdc;
}

.no-padding {
    /* padding: 12px 0px 10px 0px; */
    padding: 0;
}

.card-margin-bottom {
    margin-bottom: 2em;
}

.sort-dropdown {
    width: 30%;
    z-index: 12;
    min-width: 200px;
}

.custom-dropdown {
    background-color: rgb(239, 243, 250);
    border: 1px solid #555aa1;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    font-size: 14px;
    border-radius: 20px;
    color: black;
    z-index: 12;
}

.custom-dropdown:active {
    background-color: #555aa1 !important;
    color: white !important;
    border-color: #555aa1 !important;
}

.custom-dropdown:focus {
    background-color: #555aa1 !important;
    color: white !important;
    border-color: #555aa1 !important;
    box-shadow: 0 0 0 0.2rem rgb(85, 90, 161 / 25%) !important;
}

.custom-dropdown:hover {
    background-color: #555aa1;
    border: 1px solid #555aa1;
}

.vikaaria-dropdown {
    background-color: #555aa1;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    z-index: 12;
    font-size: 16px;
    height: 2.175rem;
    border: 2px solid #555aa1;
    font-weight: 500;
    border-radius: 10px;
    color: white;
    width: 10em;
    display: flex;
}

.vikaaria-dropdown.dropdown-menu {
    width: 100% !important;
}

.vikaaria-dropdown.dropdown-toggle:after {
    content: none;
}

.vikaaria-dropdown:active {
    background-color: #555aa1 !important;
    color: white !important;
    border-color: #555aa1 !important;
}

.vikaaria-dropdown:focus {
    background-color: #555aa1 !important;
    color: white !important;
    border-color: #555aa1 !important;
    box-shadow: 0 0 0 0.2rem rgb(85, 90, 161 / 25%) !important;
}

.vikaaria-dropdown:hover {
    background-color: #555aa1;
    border: 1px solid #555aa1;
}

.small-profile-pic {
    height: 60px;
    width: 60px;
}

.small-profile-pic:hover {
    cursor: pointer;
}

.admin-dropdown {
    background-color: white;
    color: black;
    border: none;
}

.block:hover {
    color: #555aa1;
    cursor: pointer;
}

.block:active {
    color: blue;
}

.list:hover {
    color: #555aa1;
    cursor: pointer;
}

.list:active {
    color: blue;
}

.chosenIndicator {
    width: 5px;
    background-color: rgb(87, 185, 87);
}

.notChosenIndicator {
    width: 10px;
    background-color: white;
}

.notChosenIndicator:hover {
    cursor: pointer;
    background-color: rgba(243, 243, 243, 0.815);
}

.user-name {
    margin: 0;
    font-size: 16px;
}

.admin-text {
    font-size: 14px;
}

.text-area {
    cursor: pointer;
}

.sort-down-icon {
    color: gray;
}

.sort-down-area {
    cursor: pointer;
}

.view-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    transform: translateX(20px);
}

.mobile-view-container {
    display: flex;
    width: 100%;
    color: #555aa1;
    justify-content: end;
    align-items: center;
    flex-direction: row;
    margin-left: auto;
    z-index: 100;
}

.mobile-job-header {
    display: block;
}

/* .view-svg{
    margin-right: 7px;
    width: 30px;
} */
.view-svg {
    margin-right: 7px;
}

.desktop-view-svg {
    width: 20px;
    margin-right: 7px;
    margin-top: 3px;
}

.view-counter {
    margin: 0;
    margin-right: 16px;
    font-weight: 600;
    font-size: 16px;
    margin-top: auto;
}

.block-bell-container {
    flex-direction: row;
    display: flex;
    align-items: center;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
}

.bgGreen {
    background-color: #35b85a;
}

.bgPurple {
    background-color: #7b80dc;
}

.bgYellow {
    background-color: #ffa53a;
}

.f-row {
    flex-direction: row;
}

@media (max-width: 600px) {
    .temp-body {
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;
    }

    .main {
        margin: 0;
    }

    .img-size {
        margin: 15px 0px 0px 15px;
        height: 70px;
        width: 70px;
    }
}

.vikariat-body{
    background-color: #F6F6F7;
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.topOfPage{
    background-color: white;
    position: relative;
}

/* .push-right{
    padding-left: 200px;
} */

.list-headers{
    
    font-weight: 600;
    font-size: large;
}
.teacher-name{
    color: black;
    font-weight: 600;
    font-size: medium;
}
/* .teacher-name:hover{
    color: rgba(0, 0, 0, 0.637);
} */


.gray-line{
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    /* margin-bottom: 15px; */
    padding-bottom: 15px;
    padding-top: 15px;
}
.v-row-hover{
    color: black;
}
.v-row-hover:hover{
    color: black;
    background-color: rgba(0, 0, 0, 0.068);
    text-decoration: none;
}

.text-content{
    
    font-size: medium;
}
.vikariat-card-list{
    border-radius: 15px;
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10);
}
.vikariat-card-block{
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10);
    border-radius: 10px;
    color: #000;
    transition: all .2s ease-in-out;
}
.vikariat-card-block:hover{
    color: black;
    text-decoration: none;
    transform: scale(1.05);
}
.block-title{
    display: flex;
    
}

.edit-job-icon{
   width: 26px;
   height: auto;
}
.edit-job-icon g{
    fill: black
}
.edit-job-icon path{
}

.edit-ellipsis{
    width: 5px;
}
.edit-ellipsis:hover{
    cursor: pointer;
}



/* viewUserProfile */
.user-profile-body{
    background-color: #F6F6F7;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 6rem;
    padding-top: 120px;
} 


@media (max-width: 600px){
    .vikariat-body{
        padding-left: 0;
    }
    .user-profile-body{
        padding-left: 0;
        padding-top: 0;
    }
  }
.show-body {
    background-color: #f6f6f7;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 6.5rem;
    padding-top: 120px;
    padding-right: 15px;
}

.main-user {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    /* overflow-y: auto; */
}

/* .push-right-user{
    padding-left: 200px;
} */

.topOfPage {
    background-color: white;
    position: relative;
}

.mobile-arrow-container {
    width: 4em;
    display: flex;
    align-items: center;
    padding-left: 0;
}

.mobile-arrow-container-teacher {
    width: 4em;
    display: flex;
    align-items: center;
}

.mobile-admin-container {
    width: 1.8rem;
    background-color: #555aa1;
    display: flex;
    align-items: center;
}

.mobile-admin-tips-container {
    width: 1.8rem;
    background-color: #9837ac;
    display: flex;
    align-items: center;
}

.mobile-bell-container {
    width: 1.8rem;
    background-color: #ffa53a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-sos-container {
    width: 1.8rem;
    background-color: #ff6175;
    display: flex;
    align-items: center;
}

.mobile-seen-container {
    width: 1.8rem;
    background-color: #7b80dc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-bell {
    filter: brightness(0) invert(1);
    display: block;
    margin: 0 auto;
    height: 2em;
    width: 1.2rem;
}

.mobile-bell-seen {
    display: block;
    margin: 0 auto;
    height: 2em;
    width: 1.2rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.mobile-bell-seen img {
    width: 1.2rem;
}

.bell-container {
    width: 3rem;
    background-color: #ff6175;
    display: flex;
    align-items: center;
}

.user-card {
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    border-radius: 14px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    transition: all 0.2s ease-out;
    display: grid;
}

.user-card:hover {
    text-decoration: none;
    color: black;
    transform: scale(1.04);
}

.vertical-hr-user {
    border: none;
    border-left: 1px solid #f0f0f0;
    height: 80px;
    width: 1px;
}

.long-card-2 {
    display: grid;
    flex: 1 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    outline: none;
    border: none;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.long-card-2 col {
    grid-column-start: 1;
}

.long-card-2 .image-container-2 {
    margin-left: 0;
}

.long-card-2 hr {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    height: 85%;
}

.long-card-2 div:nth-of-type(3) {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    align-items: center;
}

.long-card-2 .arrow-container {
    grid-column-start: 4;
    display: flex;
    align-items: center;
    grid-row-start: 1;
    grid-row-end: 3;
}

.long-card-2 svg {
    margin-left: auto;
}

.long-card-2:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 48px 0px rgba(13, 17, 77, 0.144);
}

.height-override {
    min-height: 130px !important;
    max-height: 130px !important;
}

.long-card {
    flex: 1 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    outline: none;
    border: none;
    overflow: hidden;
    transition: all 0.2s ease-out;
    text-decoration: none;
    color: black;
    max-height: 108px;
    min-height: 108px;
}

.long-card:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 48px 0px rgba(13, 17, 77, 0.144);
    cursor: pointer;
    text-decoration: none;
    color: black;
}

/* .long-card a{
    text-decoration: none;
} */
.long-card-gray {
    flex: 1 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    outline: none;
    background-color: rgba(179, 179, 179, 0.301);
    border: none;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.long-card-gray:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 48px 0px rgba(13, 17, 77, 0.144);
}

@media (max-width: 600px) {
    .show-body {
        min-height: calc(100vh - 80px);
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;
        /*
            padding-bottom: 5rem; */
    }

    .settings-margin {
        margin-top: 13vh;
    }

    .main-user {
        padding-top: 0;
    }

    .long-card {
        max-height: none;
    }
}

.cBold{
    font-weight: 600;
}
.cSemiBold{
    font-weight: 500;
}
.cReg{
    font-weight: 400;
}
.cBolder{
    font-weight: 900;
}

/*t = text followed by size, small=sm, large=lg etc.*/
.t-sm{
    font-size: 1.2em;
}
.t-md{
    font-size: 1.4em;
}
.t-lg{
    font-size: 1.6em;
}

/* Text Color */
.tc-dark{
    color: #2e2f31;
}
.tc-gray{
    color: #707070;
}
.apptemp-body{
    background-color: rgb(239, 243, 250);
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}
.main {
    
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* .push-right{
    padding-left: 200px;
} */

.icon-lg{
    font-size: 2em;
}

.topOfPage{
    background-color: white;
    position: relative;
}

@media (max-width: 600px){
    .apptemp-body{
        padding-left: 0;
    }
  }
.go-back-icon {
    font-size: 30px;
    margin-top: 0.5em;
    color: #555aa1;
}

.got-job-container {
    background-color: #35b85a;
    height: 13em;
    border-radius: 14px;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.other-got-job-container {
    background-color: #ff6175;
    height: 13em;
    border-radius: 14px;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.got-job-text {
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: white;
    margin: 0;
}

.got-job-text-2 {
    text-align: center;
    font-size: 1.8em;
    font-weight: 400;
    color: white;
    margin: 0;
}

.got-job-icon {
    width: 30%;
}

.full-width {
    width: 100%;
}

.apply-consent-modal .modal-content {
    border: none !important;
    border-radius: 16px;
    outline: none;
    background-color: #f6f6f7;
    padding-left: 15px;
    padding-right: 15px;
}

.apply-consent-modal-error .modal-content {
    border: none !important;
    border-radius: 16px;
    outline: none;
    background-color: #ff6175;
    padding-left: 15px;
    padding-right: 15px;
}

/* .move-down {
  top: 35%;
} */

/* NOTE: Used wherever sort is not part of page basically TAGS: -main -css */
.viewjob-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /*  overflow-y: auto; */
}

@media (max-width: 600px) {
    .got-job-icon {
        width: 100%;
    }
}

.delete-icon{
    color: #be3939;
}
.delete-icon:hover{
    cursor: pointer;
}
.color-green{
    color: rgb(52, 175, 52);
}
.sidebar{
  
}

.color-white{
  color: rgb(104, 104, 104);
  
}

.bg-nav{
  background-color: white;
}

.appCount-vik{
  width: 25px;
  height: 25px;
  background-color: rgb(238, 67, 67);
  color: white;
  margin-left: 5.9em;
  display: inline-block;
  justify-content: center;
  font-weight: normal;
  align-content: center;
  flex-direction: column;
  border-radius: 50%;
  
}

.appCount{
  width: 25px;
  height: 25px;
  background-color: rgb(238, 67, 67);
  color: white;
  margin-left: 3em;
  display: inline-block;
  justify-content: center;
  font-weight: normal;
  align-content: center;
  flex-direction: column;
  border-radius: 50%;
  
}
.countText {
  margin-top: 2px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.active-custom{
  color: #555aa1;
  font-weight: bold;
  border-right: 5px solid #555aa1;
  border-radius: 5px;
}

.addT-body{
    background-color: rgb(239, 243, 250);
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top:  50px;
}

/* .push-right{
    padding-left: 230px;
    transition: 350ms;
} */

.custom-control{
    background-color: #F6F6F7!important;
    border: none;
    height: 3rem;
    border-radius: 10px;
    font-size: medium;
    font-weight: 400;
    padding-left: 1em;
}

.custom-control:focus{
    border: 1px solid #555aa1;
    box-shadow: none;
}

.custom-control-obligatory{
    background-color: #F6F6F7!important;
    border: 1px solid #ff6175;
    height: 3rem;
    border-radius: 10px;
    font-size: medium;
    font-weight: 400;
    padding-left: 1em;
}

.custom-control-obligatory:focus{
    border: 1px solid #ff6175;
    box-shadow: none;
}

.custom-checkbox{
    margin-bottom: 0.7rem;
}

.topOfPage{
    background-color: white;
    position: relative;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.image-preview{
    height: 14rem;
    width: 14rem;
    background-color: rgb(194, 194, 194);
    border-radius: 10px;
    margin-bottom: 1em;
    border: none;
}

.image-preview-lg{
    height: 20rem;
    width: 20rem;
    background-color: rgb(194, 194, 194);
    border-radius: 10px;
    margin-bottom: 1em;
    border: none;
}

@media (max-width: 600px){
    .addT-body{
        padding-left: 0;
    }
  }

.taBody{
    background-color: rgb(239, 243, 250);
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    padding-left: 15px;
}

/* .push-right{
    padding-left: 200px;
} */

.topOfPage{
    background-color: white;
    position: relative;
}

.custom-hr-2{
    height: 1px;
    width: 80%;
    padding: 0;
    margin-top: 0.1px;
    background-color: #f5f5f5;
    border: none;
}

.ta-card{
    border: none;
    border-radius: 10px;
    
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10);
}

.taCustom-Card{
    flex: 1 1;
    flex-direction: row;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10);
    overflow: hidden;
    outline: none;
}

@media (max-width: 600px){
    .taBody{
        padding-left: 0;
    }
    .main{
        margin-top: 0;
        padding-left: 0;
    }
  }
.dots-menu{
    position: absolute;
    top: 9%;
    left: 90%;
}
.dots-menu:hover{
    cursor: pointer;
}

.teacheradmin-block{
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10);
    border-radius: 10px;
    text-decoration: none;
    color: rgb(46, 47, 49);
    transition: all .2s ease-out;
    text-align: center;
    min-width: 200px;
    max-width: 350px;
    backface-visibility: hidden;
}
/* .teacheradmin-block:hover{
    text-decoration: none;
    transform: scale(1.01);
} */
.teacheradmin-block .card-footer{
    border: none;
    background-color: #f5f6fa!important;
}

.ps-body{
    background-color: rgb(239, 243, 250);
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}
.list-text{
    font-size: 1.6em;
    font-weight: 600;
}
.list-text-dt{
    font-size: 1.6em;
    font-weight: 600;
}
.list-text-dt:hover{
    cursor: pointer;
}
.smaller{
    font-size: 1.6em;
}

.list-separator{
    width: 100%;
    height: 1px;
    border: 0px;
    background-color: #f5f5f5;
}

.toggle-label{
    font-size: 1.6em;
    color: #2e2f31;
    margin-bottom: 0;
}
.align-label{
    display: flex;
}
input.apple-switch{
    position: relative;
    -webkit-appearance: none;
            appearance: none;
    outline: none;
    width: 50px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #d0dadc;
    border-radius: 50px;
    box-shadow: inset -20px 0 0 0 #fff;
    transition-duration: 200ms;
    margin-left: auto;
}
input.apple-switch::after{
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 26px;
    height: 26px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
}
input.apple-switch:checked{
    border-color: #827aff;
    box-shadow: inset 20px 0 0 0 #827aff;
}
input.apple-switch:checked::after{
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
}

.mb-custom{
    margin-bottom: calc(1rem + 80px);
}

.question-container{
    width: 100%;
    margin-top: 2em;
}
.question-header{
    font-size: 1.6em;
    font-weight: 600;
    cursor: pointer;
    background-color: #fff;
    padding-left: 30px;
    position: relative;
    border-radius: 14px;
    margin: 0;
    display: flex;
    min-height: 70px;
    border: 1px solid #dcdcdc;
}
.question-header-open{
    font-size: 1.6em;
    font-weight: 600;
    /* width: 40%; */
    margin: 0;
    cursor: pointer;
    display: flex;
    background-color: #555aa1;
    color: white;
    padding-left: 30px;
    position: relative;
    border-radius: 14px;
    min-height: 70px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid #dcdcdc;
}

.question-text{
    margin-right: 60px;
}

.question-header::after{
    content: '';
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%) rotate(-90deg);
    width: 30px;
    height: 30px;
    background-image: url(https://app.vikaaria.se/static/media/back.b5b9eed2.svg);
    background-position: center;
    background-size: 8px;
    background-repeat: no-repeat;
    background-origin: content-box;
    margin-right: 10px;
    transition: all 0.4s ease-out;
}
.question-header-open::after{
    content: '';
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%) rotate(90deg);
    width: 30px;
    height: 30px;
    background-image: url(https://app.vikaaria.se/static/media/back_white.0e869ec7.svg);
    background-position: center;
    background-size: 8px;
    background-repeat: no-repeat;
    margin-right: 10px;
    
    transition: all 0.4s ease-out;
}

.answer-container{
    /* width: 35%; */
    /* overflow: hidden;
    transition: transform 0.3s ease-out;
    height: auto;
    transform: scaleY(1);
    transform-origin: top; */
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease;
}
.answer-header{
    padding: 10px 30px 0px 30px;
    font-size: 1.6em;
    background-color: #fff;
    font-weight: 400;
    transition: all 0.4s ease;
    border-radius: 14px;
}

.question-container .answer-container.open .question-header::after{
    transform: translateY(-50%) rotate(270deg);
}
.open{
    max-height: 1000px;
    opacity: 1;
}


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.sliding {
    background: #fff;
    overflow: hidden;
    color: #000;   
    margin: 12px 0;
    transition: all .5s ease-in-out;
    height: 0;
  }
  .sliding p{
      padding:0 15px;	
  }
  .sliding:target {
    height: auto;
  }


.support-header{
    font-size: 1.6em;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 600px){
    .question-header{
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        min-height: 40px;
    } 
    .question-header-open{
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        min-height: 40px;
    }
    .question-header::after{
        right: 10px;
    }
    .question-header-open::after{
        right: 10px;
    }
    .answer-header{
        padding: 10px 20px 0px 20px;
    }
}


.inline-body p {
  font-size: 1.6em;
  color: #606374;
  margin: 0;
  padding: 0;
}

@media (max-width: 600px) {
  .language-picker {
    margin-left: 0;
  }
}

.termsParagraf{
  font-size: 0.9rem;
}

.termsHeader{
  font-size: 1.6rem;
}
.offline {
  display: flex;
  font-size: large;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.offline button {
  margin-top: 2rem;
  height: 4rem;
  width: 20rem;
  font-size: large;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #555aa1;
  border: none;
  color: white;
  border-radius: 10px;
}
.offline button:hover {
  background-color: rgba(85, 90, 161, 0.75);
}

