.body{
    height: 100vh;
    background-color: #f8f8fa;
    position: relative;
}

.vertical-center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


hr{
    height: 1px;
    color: #d2d3d6;
    background-color: #d2d3d6;
}