.addT-body{
    background-color: rgb(239, 243, 250);
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top:  50px;
}

/* .push-right{
    padding-left: 230px;
    transition: 350ms;
} */

.custom-control{
    background-color: #F6F6F7!important;
    border: none;
    height: 3rem;
    border-radius: 10px;
    font-size: medium;
    font-weight: 400;
    padding-left: 1em;
}

.custom-control:focus{
    border: 1px solid #555aa1;
    box-shadow: none;
}

.custom-control-obligatory{
    background-color: #F6F6F7!important;
    border: 1px solid #ff6175;
    height: 3rem;
    border-radius: 10px;
    font-size: medium;
    font-weight: 400;
    padding-left: 1em;
}

.custom-control-obligatory:focus{
    border: 1px solid #ff6175;
    box-shadow: none;
}

.custom-checkbox{
    margin-bottom: 0.7rem;
}

.topOfPage{
    background-color: white;
    position: relative;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.image-preview{
    height: 14rem;
    width: 14rem;
    background-color: rgb(194, 194, 194);
    border-radius: 10px;
    margin-bottom: 1em;
    border: none;
}

.image-preview-lg{
    height: 20rem;
    width: 20rem;
    background-color: rgb(194, 194, 194);
    border-radius: 10px;
    margin-bottom: 1em;
    border: none;
}

@media (max-width: 600px){
    .addT-body{
        padding-left: 0;
    }
  }
