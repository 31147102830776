.profile-body {
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}

.obligatory{
    color: #ff6175;
}


.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* .push-right{
    padding-left: 200px;
} */

/*lt = label text */
.lt{
    font-size: 1.4em;
}

.topOfPage {
    background-color: white;
    position: relative;
}


@media (max-width: 600px){
    .profile-body{
        padding-left: 0;
    }
  }