.apptemp-body{
    background-color: rgb(239, 243, 250);
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}
.main {
    
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* .push-right{
    padding-left: 200px;
} */

.icon-lg{
    font-size: 2em;
}

.topOfPage{
    background-color: white;
    position: relative;
}

@media (max-width: 600px){
    .apptemp-body{
        padding-left: 0;
    }
  }