.list-text{
    font-size: 1.6em;
    font-weight: 600;
}
.list-text-dt{
    font-size: 1.6em;
    font-weight: 600;
}
.list-text-dt:hover{
    cursor: pointer;
}
.smaller{
    font-size: 1.6em;
}

.list-separator{
    width: 100%;
    height: 1px;
    border: 0px;
    background-color: #f5f5f5;
}

.toggle-label{
    font-size: 1.6em;
    color: #2e2f31;
    margin-bottom: 0;
}
.align-label{
    display: flex;
}
input.apple-switch{
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #d0dadc;
    border-radius: 50px;
    box-shadow: inset -20px 0 0 0 #fff;
    transition-duration: 200ms;
    margin-left: auto;
}
input.apple-switch::after{
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 26px;
    height: 26px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
}
input.apple-switch:checked{
    border-color: #827aff;
    box-shadow: inset 20px 0 0 0 #827aff;
}
input.apple-switch:checked::after{
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
}

.mb-custom{
    margin-bottom: calc(1rem + 80px);
}

.question-container{
    width: 100%;
    margin-top: 2em;
}
.question-header{
    font-size: 1.6em;
    font-weight: 600;
    cursor: pointer;
    background-color: #fff;
    padding-left: 30px;
    position: relative;
    border-radius: 14px;
    margin: 0;
    display: flex;
    min-height: 70px;
    border: 1px solid #dcdcdc;
}
.question-header-open{
    font-size: 1.6em;
    font-weight: 600;
    /* width: 40%; */
    margin: 0;
    cursor: pointer;
    display: flex;
    background-color: #555aa1;
    color: white;
    padding-left: 30px;
    position: relative;
    border-radius: 14px;
    min-height: 70px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid #dcdcdc;
}

.question-text{
    margin-right: 60px;
}

.question-header::after{
    content: '';
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%) rotate(-90deg);
    width: 30px;
    height: 30px;
    background-image: url('../navigation/icons/back.svg');
    background-position: center;
    background-size: 8px;
    background-repeat: no-repeat;
    background-origin: content-box;
    margin-right: 10px;
    transition: all 0.4s ease-out;
}
.question-header-open::after{
    content: '';
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%) rotate(90deg);
    width: 30px;
    height: 30px;
    background-image: url('../navigation/icons/back_white.svg');
    background-position: center;
    background-size: 8px;
    background-repeat: no-repeat;
    margin-right: 10px;
    
    transition: all 0.4s ease-out;
}

.answer-container{
    /* width: 35%; */
    /* overflow: hidden;
    transition: transform 0.3s ease-out;
    height: auto;
    transform: scaleY(1);
    transform-origin: top; */
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease;
}
.answer-header{
    padding: 10px 30px 0px 30px;
    font-size: 1.6em;
    background-color: #fff;
    font-weight: 400;
    transition: all 0.4s ease;
    border-radius: 14px;
}

.question-container .answer-container.open .question-header::after{
    transform: translateY(-50%) rotate(270deg);
}
.open{
    max-height: 1000px;
    opacity: 1;
}


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.sliding {
    background: #fff;
    overflow: hidden;
    color: #000;   
    margin: 12px 0;
    transition: all .5s ease-in-out;
    height: 0;
  }
  .sliding p{
      padding:0 15px;	
  }
  .sliding:target {
    height: auto;
  }


.support-header{
    font-size: 1.6em;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 600px){
    .question-header{
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        min-height: 40px;
    } 
    .question-header-open{
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        min-height: 40px;
    }
    .question-header::after{
        right: 10px;
    }
    .question-header-open::after{
        right: 10px;
    }
    .answer-header{
        padding: 10px 20px 0px 20px;
    }
}

