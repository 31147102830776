.form-control {
    font-size: 16px;
    border-color: #565a9c;
}

.form-control:focus {
    border-color: #565a9c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #bcaff6ea;
}

.btn-sos {
    background-color: #ff6175;
    font-size: 20px;
    border-radius: 10px;
    font-weight: 600;
    border: 2px solid #ff6175;
    color: white;
}

.btn-sos:hover {
    background-color: #fff;
    border: 2px solid #ff6175;
    color: #ff6175;
}

.btn-customModalError {
    background-color: white;
    font-size: 20px;
    border-radius: 10px;
    font-weight: 400;
    color: #ff6175;
    margin: 0;
}

.btn-customModalError:hover {
    background-color: rgba(255, 255, 255, 0.425);
    color: white;
}

.errorApplyCloseBtn:hover {
    transform: scale(1.1);
}

.btn-custom {
    background-color: #555aa1;
    font-size: 15px;
    text-transform: uppercase;
    height: 2.8rem;
    border: 2px solid #555aa1;
    font-weight: 600;
    letter-spacing: 0.03em;
    border-radius: 10px;
    color: white;
}

.btn-custom:hover {
    background-color: #fff;
    border: 2px solid #555aa1;
    color: #555aa1;
}

.btn-remember {
    background-color: #555aa1;
    height: 3rem;
    width: 3rem;
    border-radius: 10px;
    color: white;
}

.btn-remember:hover {
    background-color: rgba(85, 90, 161, 0.7);
}

.btn-no-remember {
    background-color: #f6f6f7;
    height: 3rem;
    width: 3rem;
    border-radius: 10px;
    color: white;
}

.btn-no-remember:hover {
    background-color: #fff;
    border: 2px solid #555aa1;
    color: #555aa1;
}

.btn-custom-reverse {
    background-color: white;
    border: 2px solid #555aa1;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    height: 2.8rem;
    border-radius: 10px;
    color: #555aa1;
}

.btn-custom-reverse:hover {
    background-color: #555aa1;
    border: 2px solid #555aa1;
    border: none;
    color: white;
}

.btn-customDropdown {
    /* background-color: white; */
    font-size: 1.4em;

    font-weight: bold;
    color: black;
}

.btn-customDropdown::after {
    color: #afafaf;
    border-width: 7px;
}

.btn-customDropdown:hover {
    /* background-color: rgba(255, 255, 255, 0.658); */
    color: rgba(0, 0, 0, 0.767);
}

.btn-customDropdown:focus {
    box-shadow: none;
}

.btn-newjob {
    background-color: #555aa1 /* #F6F6F7 */;
    font-size: 14px;

    color: #f6f6f7 /* #555aa1 */;
    padding: 14px 24px;
    border-radius: 10px;
}

.btn-newjob:hover {
    background-color: #f6f6f7 /* #555aa1 */;
    color: #555aa1 /* white */;
}

.btn-newjob:hover img {
    filter: none;
}

.btn-newjob img {
    filter: brightness(0) invert(1);
}

.btn-newjob-highlighted {
    background-color: rgb(38, 156, 211);
    font-size: 1.4em;
    color: white;
    padding: 14px 24px;
    border-radius: 16px;
    text-align: left;
}

.btn-newjob-highlighted:hover {
    background-color: rgb(95, 172, 207);
    color: white;
}

.btn-newjob-highlighted-2 {
    background-color: white;
    font-size: 1.4em;
    border: 2px solid #555aa1;
    color: #555aa1;
    padding: 14px 24px;
    border-radius: 16px;
    text-align: left;
}

.btn-newjob-highlighted-2:hover {
    background-color: #555aa1;
    border: 2px solid #555aa1;
    color: white;
}

.btn-newjob-highlighted-2:hover img {
    filter: brightness(0) invert(1);
}

.btn-sortActivate {
    background-color: #35b85a;
    font-size: 1.6em;
    color: white;
    border-radius: 12px;
}

.btn-sortActivate:hover {
    background-color: white;
    color: #35b85a;
    border: 1px solid #35b85a;
}

.btn-active {
    background-color: #35b85a;
    font-size: 1.6em;
    color: white;
    border-radius: 12px;
}

.btn-active:hover {
    background-color: white;
    color: #35b85a;
    border: 1px solid #35b85a;
}

.btn-customDelete {
    background-color: #ff6175;
    font-size: 15px;
    height: 2.8rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.03em;
    border-radius: 10px;
    color: white;
}

.btn-customDelete:hover {
    background-color: white;
    color: #ff6175;
    border: 2px solid #ff6175;
}

.btn-customDelete-reverse {
    background-color: white;
    font-size: 15px;
    height: 2.8rem;
    border: 2px solid #ff6175;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.03em;
    border-radius: 10px;
    color: #ff6175;
}

.btn-customDelete-reverse:hover {
    background-color: #ff6175;
    color: white;
    border: 2px solid #ff6175;
}

.btn-customSuccess {
    font-size: 15px;
    color: white;
    height: 2.8rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.03em;
    border-radius: 10px;
    background-color: #555aa1;
}

.btn-customSuccess:hover {
    background-color: white;
    color: #555aa1;
    border: 2px solid #555aa1;
}

.btn-deactive {
    background-color: #ff6175;
    font-size: 1.6em;

    color: white;
    border-radius: 12px;
}

.btn-deactive:hover {
    background-color: white;
    color: #ff6175;
    border: 1px solid #ff6175;
}

.btn-imageBtn {
    background-color: #555aa1;
    color: white;
    font-size: 15px;
    font-weight: 500;
    /* width: 14rem; */
    height: 2.8rem;
    border-radius: 10px;
}

.btn-imageBtn:hover {
    background-color: white;
    border: 2px solid #555aa1;
    color: #555aa1;
}

.btn-imageBtn-reverse {
    background-color: white;
    color: #555aa1;
    font-size: 15px;
    border: 2px solid #555aa1;
    font-weight: 600;
    height: 2.8rem;
    border-radius: 10px;
    margin-right: auto;
}

.btn-imageBtn-reverse:hover {
    background-color: #555aa1;
    color: white;
}

.btn-saveTeacher {
    /* width: 14rem; */
    height: 2.8rem;
    background-color: #555aa1;
    color: white;
    font-size: 18;
    font-weight: bold;
    border-radius: 10px;
}

.btn-saveTeacher:hover {
    background-color: white;
    border: 2px solid #555aa1;
    color: #555aa1;
}

.red-variant {
    background-color: #ff6175;
}

.red-variant:hover {
    color: #ff6175;
    border: 1px solid #ff6175;
}

.btn-sort {

}

.btn-sort:hover {

}

.btn-sort:focus {
    box-shadow: none;
}

.btn-sort:active {
    color: blue;
}

.list-group-item {
    background-color: #f8f8fa;

}

/* Pointer event curosr. (cursor: pointer;) */
.pe-cursor {
    cursor: pointer;
}

@media (max-width: 600px) {
    .btn-customDropdown::after {
        border-width: 6px;
    }
}