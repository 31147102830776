.taBody{
    background-color: rgb(239, 243, 250);
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    padding-left: 15px;
}

/* .push-right{
    padding-left: 200px;
} */

.topOfPage{
    background-color: white;
    position: relative;
}

.custom-hr-2{
    height: 1px;
    width: 80%;
    padding: 0;
    margin-top: 0.1px;
    background-color: #f5f5f5;
    border: none;
}

.ta-card{
    border: none;
    border-radius: 10px;
    
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10);
}

.taCustom-Card{
    flex: 1;
    flex-direction: row;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10);
    overflow: hidden;
    outline: none;
}

@media (max-width: 600px){
    .taBody{
        padding-left: 0;
    }
    .main{
        margin-top: 0;
        padding-left: 0;
    }
  }