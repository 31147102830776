.dots-menu{
    position: absolute;
    top: 9%;
    left: 90%;
}
.dots-menu:hover{
    cursor: pointer;
}

.teacheradmin-block{
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10);
    border-radius: 10px;
    text-decoration: none;
    color: rgb(46, 47, 49);
    transition: all .2s ease-out;
    text-align: center;
    min-width: 200px;
    max-width: 350px;
    backface-visibility: hidden;
}
/* .teacheradmin-block:hover{
    text-decoration: none;
    transform: scale(1.01);
} */
.teacheradmin-block .card-footer{
    border: none;
    background-color: #f5f6fa!important;
}
