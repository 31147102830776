.body{
    min-height: 100vh;
    background-color: #f8f8fa;
    position: relative;
}

.vertical-center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.forgot-link{
    font-size: 14px;
    
    color: #5333bd;
    text-decoration: underline;
}
.forgot-link:hover{
    color: #5333bd6e;
}
.pre-text {
    font-size: 14px;
    
}

.saved-email{
    font-size: 16px;
    
    color: #00002f;
    text-align: center;
}
.change-email{
    font-size: 16px;
    text-decoration: underline;
    
    color: #5333bd;
}
.change-email:hover{
    color: #5333bd6e;
}

.re-captcha{
    font-size: 12px;
    
    color: #a8a8a8;
    text-align: center;
}

.re-captcha a{
    color: #484848;
}

hr{
    height: 1px;
    color: #d2d3d6;
    background-color: #d2d3d6;
    width: 60%;
}