.show-body {
    background-color: #f6f6f7;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 6.5rem;
    padding-top: 120px;
    padding-right: 15px;
}

.main-user {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    /* overflow-y: auto; */
}

/* .push-right-user{
    padding-left: 200px;
} */

.topOfPage {
    background-color: white;
    position: relative;
}

.mobile-arrow-container {
    width: 4em;
    display: flex;
    align-items: center;
    padding-left: 0;
}

.mobile-arrow-container-teacher {
    width: 4em;
    display: flex;
    align-items: center;
}

.mobile-admin-container {
    width: 1.8rem;
    background-color: #555aa1;
    display: flex;
    align-items: center;
}

.mobile-admin-tips-container {
    width: 1.8rem;
    background-color: #9837ac;
    display: flex;
    align-items: center;
}

.mobile-bell-container {
    width: 1.8rem;
    background-color: #ffa53a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-sos-container {
    width: 1.8rem;
    background-color: #ff6175;
    display: flex;
    align-items: center;
}

.mobile-seen-container {
    width: 1.8rem;
    background-color: #7b80dc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-bell {
    filter: brightness(0) invert(1);
    display: block;
    margin: 0 auto;
    height: 2em;
    width: 1.2rem;
}

.mobile-bell-seen {
    display: block;
    margin: 0 auto;
    height: 2em;
    width: 1.2rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.mobile-bell-seen img {
    width: 1.2rem;
}

.bell-container {
    width: 3rem;
    background-color: #ff6175;
    display: flex;
    align-items: center;
}

.user-card {
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    border-radius: 14px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    transition: all 0.2s ease-out;
    display: grid;
}

.user-card:hover {
    text-decoration: none;
    color: black;
    transform: scale(1.04);
}

.vertical-hr-user {
    border: none;
    border-left: 1px solid #f0f0f0;
    height: 80px;
    width: 1px;
}

.long-card-2 {
    display: grid;
    flex: 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    outline: none;
    border: none;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.long-card-2 col {
    grid-column-start: 1;
}

.long-card-2 .image-container-2 {
    margin-left: 0;
}

.long-card-2 hr {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    height: 85%;
}

.long-card-2 div:nth-of-type(3) {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    align-items: center;
}

.long-card-2 .arrow-container {
    grid-column-start: 4;
    display: flex;
    align-items: center;
    grid-row-start: 1;
    grid-row-end: 3;
}

.long-card-2 svg {
    margin-left: auto;
}

.long-card-2:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 48px 0px rgba(13, 17, 77, 0.144);
}

.height-override {
    min-height: 130px !important;
    max-height: 130px !important;
}

.long-card {
    flex: 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    outline: none;
    border: none;
    overflow: hidden;
    transition: all 0.2s ease-out;
    text-decoration: none;
    color: black;
    max-height: 108px;
    min-height: 108px;
}

.long-card:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 48px 0px rgba(13, 17, 77, 0.144);
    cursor: pointer;
    text-decoration: none;
    color: black;
}

/* .long-card a{
    text-decoration: none;
} */
.long-card-gray {
    flex: 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    outline: none;
    background-color: rgba(179, 179, 179, 0.301);
    border: none;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.long-card-gray:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 48px 0px rgba(13, 17, 77, 0.144);
}

@media (max-width: 600px) {
    .show-body {
        min-height: calc(100vh - 80px);
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;
        /*
            padding-bottom: 5rem; */
    }

    .settings-margin {
        margin-top: 13vh;
    }

    .main-user {
        padding-top: 0;
    }

    .long-card {
        max-height: none;
    }
}
