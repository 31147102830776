.pos-body {
    background-color: rgb(239, 243, 250);
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-left: 6rem;
    padding-top: 120px;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
option:disabled{
    color: rgba(230, 230, 230, 0.753);

}

.modal.sos-modal .modal-dialog .modal-content{
    border: none;
    border-radius: 20px;
    outline: none;
}

/* .push-right{
    padding-left: 230px;
} */

.subject-picker{
   
}
.react-datepicker-popper{
    z-index: 10000;
}

.topOfPage {
    background-color: white;
    position: relative;
}

@media (max-width: 600px){
    .pos-body{
        padding-left: 0;
    }
  }

  .add-job-pad{
      padding-left: 25px;
      padding-right: 30px;
  }
